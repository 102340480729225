import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setAll } from "../../helpers/set-all";


export const loadCabinet = createAsyncThunk("cabinet/loadCabinet", async () => {
    let headers = {
        "api-key": process.env.REACT_APP_API_KEY,
    };
    const site = `"CustomerSite":'${process.env.REACT_APP_SITE}'`;
    const data = await axios.get(process.env.REACT_APP_API_BASE_URL + "items/Cabinet", {
        headers: headers,
        params: { populate: "1", filter: `{${site}}` },
    });
    return {
        all: data.data
    }
});


const cabinetSlice = createSlice({
    name: "cabinet",
    initialState: {
        loading: true,
        all: []
    },
    reducers: {
        fetchCabinetSuccess(state, action) {
            setAll(state, action.payload);
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadCabinet.pending, state => {
                state.loading = true;
            })
            .addCase(loadCabinet.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadCabinet.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
    }

})

export default cabinetSlice.reducer;

export const { fetchCabinetSuccess } = cabinetSlice.actions;