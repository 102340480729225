import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setAll } from "../../helpers/set-all";

export const loadCabinetCategory = createAsyncThunk("cabinetCategory/loadCategory", async () => {
    let headers = {
        "api-key": process.env.REACT_APP_API_KEY,
    };
    
    const data = await axios.get(process.env.REACT_APP_API_BASE_URL + "items/CabinetCategory", {
        headers: headers
    });
    return {
        all: data.data
    }
});

const cabinetCategorySlice = createSlice({
    name: "cabinetCategory",
    initialState: {
        loading: true,
        all: []
    },
    reducers: {
        fetchCabinetCategorySuccess(state, action) {
            setAll(state, action.payload);
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadCabinetCategory.pending, state => {
                state.loading = true;
            })
            .addCase(loadCabinetCategory.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadCabinetCategory.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
    }

})

export default cabinetCategorySlice.reducer;

export const { fetchCabinetCategorySuccess } = cabinetCategorySlice.actions;