import {constants} from '../../types/constants'
//import { HandCashConnect } from '@handcash/handcash-connect'
import Cookies from 'js-cookie';

const API_URL = process.env.NEXT_PUBLIC_API_URL || constants.API_URL
const KEY_TOKEN = "hc-token";

const HANDCASH_APP = process.env.NEXT_PUBLIC_HANDCASH_APP || constants.APP_NAME;

//uses localstorage, calls the api back end
// See HandcashClient for direct client
export default class HandcashApiClient {
  MIN_BALANCE = 3000;
  /*private*/ msaddress;
  /*private*/ _app = HANDCASH_APP;
  /*private*/ _balance/*: any*/ = null;
  constructor(msaddress/*?: string*/) {
    //address is required for pay method!
    // if (msaddress === undefined)
    //   throw Error(`msaddress undefined!`);
    this.msaddress = msaddress;
  }
  get islinked() {
    return this.getMoneyStreamToken() !== null;
  }
  get hasBalanceAvailable() {
    console.log(`HCBAL`, this._balance);
    return (
      this._balance && this._balance.spendableSatoshiBalance > this.MIN_BALANCE
    );
  }
  getMoneyStreamToken() {
    return localStorage.getItem(KEY_TOKEN);
  }
  storeToken(token/*: any*/) {
    localStorage.setItem(KEY_TOKEN, token);
    Cookies.set(KEY_TOKEN, token, { expires: 7 }); // Expires in 1 day
  }
  clearToken() {
    localStorage.removeItem(KEY_TOKEN);
  }

  // getApp() {
  //   const hcappid = this.getAppId()
  //   const hcapp = new HandCashConnect(hcappid, 'secret')
  //   return hcapp
  // }

  // authentication is not required for this call??
  async appApproveUrl() {
    // const response = await fetch(
    //   `${API_URL}/handcash/loginredirect/${this._app}`,
    //   {
    //     method: "GET",
    //     headers: {
    //       Authorization: `Bearer ${this.getMoneyStreamToken()}`,
    //     },
    //   }
    // );
    // const apiresult = await response.text();
    // return apiresult;

    // const hcapp = this.getApp(constants.APP_NAME);
    // const redirectUrl = hcapp.getRedirectionUrl({'referrerHandle': 'fullcycle'})

    return `https://app.handcash.io/#/authorizeApp?appId=${constants.APP_HANDCASH}`
  }

  async getbalance()/*: Promise<any>*/ {
    const response = await fetch(`${API_URL}/handcash/balance/${this._app}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.getMoneyStreamToken()}`,
      },
    });
    const apiresult = await response.json();
    //{"spendableSatoshiBalance":180146,"spendableFiatBalance":0.3195,"currencyCode":"USD"}
    return apiresult;
  }

  async test() {
    const response = await fetch(`${API_URL}/handcash/test/${this._app}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.getMoneyStreamToken()}`,
      },
    });
    const apiresult = await response.text();
    return apiresult;
  }

  //TODO: use JWT in api
  async pay(config/*: any*/) {
    if (!config.payto) {
      throw new Error(`handcash-client.tsx: payto address missing!`);
    }
    //TODO: check if duros passed and convert duros to SAT
    const payment = {
      to: config.payto,
      currencyCode: "USD",
      amount: config.amount,
    };
    if (config.duros) {
      payment.currencyCode = "SAT";
      payment.amount = config.duros * 500;
    }
    // TODO: refactor this platform fee
    // TODO: check if catn8 is the correct payto handle
    const PLATFORM_PERCENT = 0.01
    const platformpayment = {
      to: constants.PLATFORM_PAYMAIL,
      currencyCode: payment.currencyCode,
      amount: payment.amount * PLATFORM_PERCENT,
    }
    payment.amount -= platformpayment.amount
    const conf = {
      description: "LIVESTREAM",
      appAction: "tip",
      payments: [payment, platformpayment],
    }
    //console.log(`${API_URL}/handcash/pay/${this._app}`)
    const response = await fetch(`${API_URL}/handcash/pay/${this._app}`, {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.getMoneyStreamToken()}`,
      },
      credentials: "same-origin",
      body: JSON.stringify({
        config: conf,
      }),
    });
    const apiresult = await response.json();
    return apiresult;
  }

  async profile()/*: Promise<any>*/ {
    const url = `${API_URL}/handcash/profile/${this._app}`;
    console.log(`PROFILEURL`, url);
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.getMoneyStreamToken()}`,
      },
    });
    //const apiresult = await response.text();
    const apiresult = await response.json();
    console.log(`PROFILE`, apiresult);
    return apiresult;
  }
}
