import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import Header from "../../Component/Header/Header";
import Navbar from "../../Component/Navbar/Navbar";
import HomeVideos from "../../Component/HomeVideos/HomeVideos";
import TopLink from "../../Component/TopLink/TopLink";
import './Home.css';
import HomeVideoCabinets from "../../Component/HomeVideoCabinets/HomeVideoCabinets";
import { useLocation } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';

const Home = () => {
    const [country, setCountry] = useState("usa");
    const siteStyle = useSelector(state => state.style)
  


    const location = useLocation();

    useEffect(() => {
      countryFilter("usa");
      document.title = "Home";
        // Check if navigated here with a specific state
        if (location.state?.fromCheckLogin) {
            toast("Login using Handcash or Social Login", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, [location]);
    const countryFilter = (title) => {
      setCountry(title);
    };
    return (
      <>
        <div
          style={{ background: siteStyle.BodyBackgroundColor, padding: "0.5rem" }}
        >
          <div className="home-container mx-auto px-4">
            <Navbar />
            <TopLink countryFilter={countryFilter} country={country} />
            <Header />
            {/* <HomeVideos country={country} /> */}
            <HomeVideoCabinets country={country} />
            <ToastContainer />

          </div>
        </div>
      </>
    );
  };
  

export default Home;