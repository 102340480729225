import React from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import './PersonCoin.css';
const PersonCoin = ({ coin }) => {
  const siteStyle = useSelector(state => state.style);
  const Coins = siteStyle.Coins;

  return (
    <>
      {Coins && Coins["repeatCoins"] !== null
        ? Object.entries(Coins["repeatCoins"]).map(([k, v], ind) =>
          v !== null ? (
            v.coinLabel === coin.toUpperCase() ? (
              <div className="person-coin w-8 md:w-11 lg:w-16 mx-1" key={ind}>
              <Link to={'/person/' + coin} title={coin}>
                <img
                  src={v.thumbnailURL}
                  alt={coin}
                />
              </Link>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )
        )
        : ""}
    </>
  )
}

export default PersonCoin