import { configureStore } from "@reduxjs/toolkit";
import personReducer from "./slices/person-slice";
import styleReducer from "./slices/style-slice";
import videoReducer from "./slices/video-slice";
import categoryReducer from "./slices/category-slice";
import cabinetCategoryReducer from "./slices/cabinetCategory-slice";
import cabinetItemSliceReducer from "./slices/cabinetItems-slice";
import cabinetSliceReducer from "./slices/cabinet-slice";
const store = configureStore({
    reducer: {
        person: personReducer,
        style: styleReducer,
        video: videoReducer,
        category: categoryReducer,
        cabinetCategory:cabinetCategoryReducer,
        cabinetItems:cabinetItemSliceReducer,
        cabinet:cabinetSliceReducer
    }
});

export default store;