import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../Component/Navbar/Navbar";
import Coin from "../../Component/Coin/Coin";
import CoinWithoutImg from "../../Component/CoinWithoutImg/CoinWithoutImg";
import { loadPersonInfo } from "../../store/slices/person-slice";
import './Person.css';
import { PersonListItem } from "../../Component/PersonListItem/PersonListItem";

const Person = () => {
  const { label } = useParams();
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.person.loading);
  const person = useSelector(state => state.person.all);

  const siteStyle = useSelector(state => state.style);

  useEffect(() => {
    dispatch(loadPersonInfo({ label }));
    document.title = `Person| ${label}`;
  }, [label]);

  return (
    <>
      
      <div
        className=""
        style={{ background: siteStyle.BodyBackgroundColor, padding: "0.5rem" }}
      >
        <div className="person-container mx-auto px-4">
          <Navbar />
          <CoinWithoutImg />
          <Coin />
          <div className="person-list-container">
            {!isLoading && person.map((data) => (
              <PersonListItem person={data} key={data._id} category={label} />
            ))}
            {isLoading ? (
              <h1 className="noData">Loading...</h1>
            ) : (
              person.length < 1 && (
                <h1 className="noData">Data not found...</h1>
              )
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Person;