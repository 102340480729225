import React, { useContext, useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import "./Component/handcash/handcash.css";
import Home from "./pages/Home";
import Person from "./pages/Person";
import PersonDetail from "./pages/PersonDetail";
import Category from "./pages/Category";
import VideoDetail from "./pages/VideoDetail";
import HandcashSuccess from "./pages/handcash/success";
import Loader from "./Component/Loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { loadStyle } from "./store/slices/style-slice";
import { loadVideo } from "./store/slices/video-slice";
import { loadCategory } from "./store/slices/category-slice";
import { loadAllPersonInfo } from "./store/slices/person-slice";
import { loadCabinetCategory } from "./store/slices/cabinetCategory-slice";
import { loadCabinetItems } from "./store/slices/cabinetItems-slice";
import { loadCabinet } from "./store/slices/cabinet-slice";
import VideoFileCabinet from "./pages/VideoFileCabinet";
import { UserContext } from "./context/UserContext";
import { TokenContext } from "./context/TokenContext";
import CheckLogin from "./Component/CheckLogin";
import Cookies from 'js-cookie';
import 'react-toastify/dist/ReactToastify.css'; // Import toastify css
import { ToastContainer } from 'react-toastify';

function App() {
  const {user} = useContext(UserContext);
  const {bcsToken,setBCSToken} = useContext(TokenContext);
  // Check for tokens in cookies
  console.log({bcsToken})
  // console.log({user})
  const dispatch = useDispatch();
  const isStyleLoading = useSelector(state => state.style.loading);
  const isVidoeLoading = useSelector(state => state.video.loading);
  const isCategoryLoading = useSelector(state => state.category.loading);
  const isAllPersonLoding = useSelector(state => state.person.all_loading);
  const isCabinetCategoryLoading = useSelector(state => state.cabinetCategory.loading )
  const isCabinetItemsLoading = useSelector(state => state.cabinetItems.loading )
  const isCabinetLoading = useSelector(state => state.cabinet.loading )
  useEffect(()=>{
    dispatch(loadStyle());
    dispatch(loadVideo());
    dispatch(loadCategory());
    dispatch(loadAllPersonInfo());
    dispatch(loadCabinetCategory());
    dispatch(loadCabinetItems());
    dispatch(loadCabinet());
  }, []);



  if (isStyleLoading || isVidoeLoading || isCategoryLoading  || isAllPersonLoding || isCabinetCategoryLoading || isCabinetItemsLoading || isCabinetLoading) return <Loader />;

  
  return (
      <>
    <Routes>
      <Route index element={<Home />} />
      <Route path="person/:label" element={bcsToken ? <Person /> : <CheckLogin />} />
      <Route path="person/:label/:id/:name" element={bcsToken ? <PersonDetail /> : <CheckLogin />} />
      <Route path="category/:category" element={bcsToken ? <Category /> : <CheckLogin />} />
      <Route path="video/:id" element={bcsToken ? <VideoDetail /> : <CheckLogin />} />
      <Route path="cabinet/:id" element={bcsToken ? <VideoFileCabinet /> : <CheckLogin />} />
      <Route path="handcash/success" element={<HandcashSuccess />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
    <ToastContainer />

      </>
  );
}

export default App;
