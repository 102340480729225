import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Navbar from "../../Component/Navbar/Navbar";
import CoinWithoutImg from "../../Component/CoinWithoutImg/CoinWithoutImg";
import CabinetCategories from "../../Component/CabinetCategories/CabinetCategories";
// Import Swiper styles


import './VideoFileCabinet.css';

const VideoFileCabinet = () => {
    const { id } = useParams();
    const siteStyle = useSelector(state => state.style);
    const cabinet = useSelector(state => state.cabinet.all);

    const getCabinet = (id) => {
        for (let i=0; i < cabinet.length; i++) {
            if (cabinet[i]._id === id) {
                return cabinet[i];
            }
        }
    }

    useEffect(() => {
        document.title = `Video Cabinet | ${getCabinet(id).Name}`;
    }, []);
    
    return (
        <>
            <div
                className=""
                style={{ background: siteStyle.BodyBackgroundColor, padding: "0.5rem" }}
            >
                <div className="select-video-container mx-auto px-4">
                    <Navbar />
                    <CoinWithoutImg />
                    <CabinetCategories cabinet={getCabinet(id)}/>
                </div>
            </div>

        </>
    )
}

export default VideoFileCabinet;

