import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Iframe from "react-iframe";
import { loadVideoWithID } from "../../store/slices/video-slice";
import { PersonListItem } from "../../Component/PersonListItem/PersonListItem";
import Navbar from "../../Component/Navbar/Navbar";
import CoinWithoutImg from "../../Component/CoinWithoutImg/CoinWithoutImg";
import { formatted_bio } from "../../helpers/formatted_bio";
import './VideoDetail.css';

const VideoDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const siteStyle = useSelector(state => state.style);
  const video = useSelector(state => state.video.selectedVideo);
  const isLoading = useSelector(state => state.video.selectedVideoLoading);
  const allPersons = useSelector(state => state.person.allPersonInfo)

  const userExists = (id, persons) => {
    return persons.some(function(el) {
      return el._id === id;
    }); 
  }
  useEffect(() => {
    dispatch(loadVideoWithID({ id }));
  }, [id]);
  useEffect(() => {
    if (!isLoading) {
      document.title = `VIDEO|${video.Title}`;
    }
  }, [video]);

  return (
    <>

      <div
        className=""
        style={{ background: siteStyle.BodyBackgroundColor, padding: "0.5rem" }}
      >
        <div className="select-video-container mx-auto px-4">
          <Navbar />
          <CoinWithoutImg />
          {
            !isLoading && (
              <>
                <div className="video-player-container">
                  <p className="video-title font_family">{video.Title}</p>
                  <Iframe url="https://player.vimeo.com/video/403530213" width="600" height="400" frameborder="0" allow="autoplay; fullscreen" allowfullscreen/>
                  <p className="video-description" dangerouslySetInnerHTML={{ __html: formatted_bio(video.Description, 0) }} />
                </div>
                {
                  allPersons.map((person) => (
                    userExists(person._id, video.Person) &&
                    <PersonListItem person={person} key={person._id}/>
                  ))
                }
              </>
            )
          }
        </div>
      </div>
    </>
  );
};

export default VideoDetail;