const MS_LIVE_URL = "https://mymoney.stream";
const API_URL = process.env.NEXT_PUBLIC_API_URL || "https://api.mymoney.stream";
const BOT_API =
  process.env.NEXT_PUBLIC_BOT_API || "https://cash.bitcoinofthings.com";
const ORIGIN_URL = process.env.NEXT_PUBLIC_MONEYSTREAM_URL || MS_LIVE_URL;

export function isTest() {
  return ORIGIN_URL !== MS_LIVE_URL;
}

export function getUrlParam(key) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  // console.log(`URLPARAMS for ${search}`,params)
  return params.get(key);
}

export function tryJsonParse(mightbejson) {
  try {
    return JSON.parse(mightbejson);
  } catch {
    console.error(`error parsing ${mightbejson}`);
  }
  return null;
}

export function currentUser() {
  const currentuser = localStorage.getItem("user");
  const jsonuser = JSON.parse(currentuser);
  // console.log(`currentuser`, jsonuser)
  return jsonuser;
}

export function isloggedin() {
  const currentuser = currentUser();
  // user must have wallet to be logged in
  return currentuser !== null && currentuser.encryptedwallet != null;
}

function getToken() {
  return localStorage.getItem("access_token");
}

export async function test() {
  const response = await fetch(`${API_URL}/test`, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      //'Accept' : 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    credentials: "same-origin",
  });
  console.log(response);
  //const apiresult = await response.json()
  const apiresult = await response.text();
  console.log(apiresult);
  if (apiresult && apiresult.result === "success") {
    return true;
  }
  return false;
}

function doLogout() {
  localStorage.removeItem("user");
  localStorage.removeItem("access_token");
  localStorage.removeItem("funding");
  return true;
}

export async function logout() {
  console.log(API_URL);
  const response = await fetch(`${API_URL}/logout`, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      //'Accept' : 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
    credentials: "same-origin",
  });
  const apiresult = await response.json();
  console.log(apiresult);
  if (apiresult && apiresult.statusCode === 401) {
    //Unauthorized, probably expired token
    return doLogout();
  }
  if (apiresult && apiresult.result === "success") {
    return doLogout();
  }
  return false;
}

export async function login(loginuser, loginpassword) {
  console.log("login");
  const response = await fetch(`${API_URL}/login`, {
    mode: "cors",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      username: loginuser,
      password: loginpassword,
    }),
  });
  const apiresult = await response.json();
  console.log(apiresult);
  if (apiresult) {
    if (apiresult.access_token) {
      localStorage.setItem("access_token", apiresult.access_token);
      localStorage.setItem("user", JSON.stringify(apiresult.user));
      // TODO: raise logged in event???
      return true;
    } else {
      throw new Error(JSON.stringify(apiresult));
    }
  }
  return false;
}

export async function register(name, loginuser, loginpassword) {
  console.log("register");
  const response = await fetch(`${API_URL}/register`, {
    mode: "cors",
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      name,
      loginuser,
      loginpassword,
    }),
  });
  const apiresult = await response.json();
  if (apiresult) {
    if (apiresult.result && apiresult.result === "success") {
      // make user log in to set local storage correctly
      localStorage.setItem("user", JSON.stringify(apiresult.data));
      return true;
    } else {
      if (apiresult.details === "dupe reg") {
        throw new Error("Already registered. Try logging in.");
      } else {
        throw new Error(JSON.stringify(apiresult));
      }
    }
  }
  return false;
}

export async function sendChangePassword(newpass) {
  const response = await fetch(`${API_URL}/change`, {
    mode: "cors",
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getToken()}`,
    },
    credentials: "same-origin",
    body: JSON.stringify({
      loginpassword: newpass,
    }),
  });
  const apiresult = await response.json();
  console.log(apiresult);
  if (apiresult && apiresult.statusCode === 401) {
    //Unauthorized, probably expired token
    alert(`Unauthorized`);
    return false;
  }
  if (apiresult && apiresult.result === "success") {
    return true;
  }
  return false;
}

export function wait(delay /*:number*/) {
  return new Promise((res) => setTimeout(res, delay));
}

export async function leave(topic) {
  const message = {
    v1: {
      topic: topic,
      payload: topic,
      messageId: "TODO123",
    },
  };
  // to close the popover
  try {
    console.log(message);
    // will get handled by Framemaker/messagehandler
    // window.parent makes most sense? upstream
    // listener is divelement.iframe.contentwindow
    await window.postMessage(message, ORIGIN_URL);
  } catch (err) {
    console.error(topic, err);
  }
  //await window.parent.postMessage({v1:{topic:'refresh',payload:'refresh'}},'*')
  // how will this page know when to refresh?
}

// this needs to work for spends too, i.e. unfunding
export function blastOutFundingRefresh(items) {
  console.log(`updating funding`, items);
  localStorage.removeItem("funding");
  localStorage.setItem("funding", JSON.stringify(items));
}

// TODO: put into user class
export function getUserFromStorage() {
  const storagedata = localStorage.getItem("user");
  const user = JSON.parse(storagedata);
  return user;
}

export function isSelected(thisuser, fundingoption) {
  if (fundingoption == true) return true;
  if (!thisuser?.config) return true;
  if (
    !thisuser?.config.hasmoneybutton &&
    !thisuser?.config.hashandcash &&
    !thisuser?.config.hasrelayx &&
    !thisuser?.config.hasdotwallet
  ) {
    return true;
  }
  return false;
}
// end of user class

export function openWindow(url) {
  window.open(url, "_blank");
}

export function openWindowTransaction(txid) {
  window.open(`https://whatsonchain.com/tx/${txid}`, "_blank");
}
export function openWindowAddress(address) {
  window.open(`https://whatsonchain.com/address/${address}`, "_blank");
}
export function TokenWallet(address) {
  window.open(`https://whatsonchain.com/address/${address}`, "_blank");
}

export function isValidTxid(txid) {
  if (!txid) return false;
  if (txid.length == 64) {
    return true;
  }
  return false;
}

export async function resolvePaymail(paymail) {
  const response = await fetch(`${BOT_API}/paymail/resolve/${paymail}`, {
    method: "GET",
  });
  const apiresult = await response.json();
  console.log(apiresult);
  if (apiresult && apiresult.statusCode === 401) {
    //Unauthorized, probably expired token
    return null;
  }
  return apiresult;
}
