import { initializeApp } from "firebase/app";
import { getAuth, signInWithPopup, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyCXh8TOFLUz9nbMVL1SaMWK5GWPIhRcKmg",
  authDomain: "yourlive-ef04e.firebaseapp.com",
  databaseURL: "https://yourlive-ef04e-default-rtdb.firebaseio.com",
  projectId: "yourlive-ef04e",
  storageBucket: "yourlive-ef04e.appspot.com",
  messagingSenderId: "433860178802",
  appId: "1:433860178802:web:bcf10ec8a35aaf6ea063eb",
  measurementId: "G-9DTWK91YQV"
};

// Initialize Firebase
initializeApp(firebaseConfig);

const provider = new GoogleAuthProvider();
provider.setCustomParameters({
    prompt: "select_account"
})

export const auth = getAuth();
export const signInWithGooglePopup = () => signInWithPopup(auth, provider);
export const google = new GoogleAuthProvider();
export const facebook = new FacebookAuthProvider();