import { useState, createContext,useEffect}  from "react";
import Cookies from 'js-cookie';

export const TokenContext = createContext();

export const TokenContextProvider = (props) => {
    const [bcsToken, setBCSToken] = useState();
    useEffect(() => {
        // Only set the token from cookies if it's not already defined
        if (!bcsToken) {
            const token = Cookies.get('socialToken') || Cookies.get('hc-token');
            if (token) {
                setBCSToken(token);
            }
        }
    }, [bcsToken]);

    return (
        <TokenContext.Provider
            value={{
                bcsToken,
                setBCSToken
            }}
        >
            {props.children}
        </TokenContext.Provider>
    )
}


