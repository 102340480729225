import React from 'react'
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import './coin.css';

const Coin = () => {
  const { label } = useParams();
  const siteStyle = useSelector(state => state.style);
  const Coins = siteStyle.Coins;

  return (
    <div className="flex justify-center">
      {Coins && Coins["repeatCoins"] !== null
        ? Object.entries(Coins["repeatCoins"]).map(([k, v], ind) =>
          v !== null ? (
            v.coinLabel === label.toUpperCase() ? (
              <div key={ind} className='selected-coin'>
                <img
                  className="w-20 md:w-28 lg:w-36"
                  src={v.thumbnailURL}
                  alt={v.coinLabel}
                />
                <p className="text-white text-center text-uppercase font_family m-0">
                  {v.coinLabel}
                </p>
              </div>
            ) : (
              ""
            )
          ) : (
            ""
          )
        )
        : ""}
    </div>
  )
}

export default Coin