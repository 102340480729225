import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";

const CoinWithoutImg = () => {
  const { label } = useParams();
  const siteStyle = useSelector(state => state.style);
  const Coins = siteStyle.Coins;
  return (
    <>
      <Swiper
        style={{ "--swiper-pagination-bullet-inactive-opacity": "1" }}
        slidesPerView={"7"}
        spaceBetween={5}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper m-0 home-coins"
        navigation={true}
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          // when window width is >= 530px
          530: {
            slidesPerView: 3,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 4,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 6,
          },
          // when window width is >= 1440px
          1440: {
            slidesPerView: 7,
          },
          // when window width is >= 1800px
          1800: {
            slidesPerView: 9,
          },
        }}
      >
        <SwiperSlide>
          <Link to="/">
            <p className="text-white text-center text-uppercase font_family m-0">Home</p>
          </Link>
        </SwiperSlide>
        {Coins && Coins["repeatCoins"] !== null
          ? Object.entries(Coins["repeatCoins"]).map(([k, v], ind) =>
            v !== null ? (
              <SwiperSlide key={ind}>
                <Link to={"/person/" + v.coinLabel.toLowerCase()}>
                  <p
                    className={`text-white text-center text-uppercase font_family m-0 ${label && label.toUpperCase() === v.coinLabel ? "active" : ""
                      }`}
                  >
                    {v.coinLabel}
                  </p>
                </Link>
              </SwiperSlide>
            ) : (
              ""
            )
          )
          : ""}
      </Swiper>
    </>
  );
};

export default CoinWithoutImg;
