import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setAll } from "../../helpers/set-all";

export const loadCabinetItems = createAsyncThunk("cabinetItems/loadItems", async () => {
    let headers = {
        "api-key": process.env.REACT_APP_API_KEY,
    };
    
    const data = await axios.get(process.env.REACT_APP_API_BASE_URL + "items/CabinetItem", {
        headers: headers
    });
    return {
        all: data.data
    }
});

const cabinetItemSlice = createSlice({
    name: "cabinetItems",
    initialState: {
        loading: true,
        all: []
    },
    reducers: {
        fetchCabinetItemSuccess(state, action) {
            setAll(state, action.payload);
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadCabinetItems.pending, state => {
                state.loading = true;
            })
            .addCase(loadCabinetItems.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadCabinetItems.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
    }

})

export default cabinetItemSlice.reducer;

export const { fetchCabinetItemSuccess } = cabinetItemSlice.actions;