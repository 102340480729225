import { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {constants} from '../../types/constants'

import { getUrlParam } from "../../Component/handcash/api";
import HandcashClient from "../../Component/handcash/HandcashClient";
// import {useStore} from '../../client/components/handcash/useGlobalState'
import Box from "@mui/material/Box";
import Typography from '@mui/material/Typography'
import { TokenContext } from "../../context/TokenContext";
import { UserContext } from "../../context/UserContext";

export default function HandcashSuccess() {
  const [token, setToken] = useState("");
  const { user,setUser } = useContext(UserContext);
  const {bcsToken, setBCSToken} = useContext(TokenContext);

  const [productid, setProductid] = useState("");
  const navigate = useNavigate();
  //const {user} = useContext(UserContext);
  //const {user, login} = useStore()

  useEffect(() => {
    const tok = getUrlParam("authToken");
    console.log(`HANDCASH TOKEN`, tok);
    setProductid(getUrlParam("pid") || "");
    setToken(tok || "");
  }, []);
  useEffect(() => {
    const hc = new HandcashClient("");
    async function doLogin() {
      //TODO: enable profile fetch
      // const profile = await hc.profile()
      // hc.storeUser(profile)
      //login(token,profile)
      navigate('/') //, undefined, { shallow: true }
    }
    if (token) {
      console.log({token})
      hc.storeToken(token);
      setBCSToken(token)
      doLogin()
    }
  }, [token]);

  return (
    <div
    style={{
      backgroundImage: "radial-gradient(#000,#15073d,#15073d,#000)",
      position: "fixed",
      top: "64px",
      right: 0,
      left: 0,
      bottom: 0,
    }}
  >
    <Box
      style={{
        backgroundImage: "radial-gradient(#000,#15073d,#15073d,#000)",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "calc(100vh - 64px)",
      }}
    ></Box>
    <Box
      sx={{
        display: "flex",
        position: "relative",
      }}
    >
      <Box component="main" sx={{ flexGrow: 1 }}>
          <Box
          style={{
            position: "relative",
            paddingTop: "5%" /* 720 / 1280 = 0.5625 */,
            paddingLeft:"20%",
            paddingRight:"20%",
            maxHeight: "calc(100vh - 64px)",
          }}
        >
        <Typography variant="h5" color="white" align="center">
          Creating a scene...
        </Typography>
        {/* <div className="center">
          <div className="circle pulse"></div>
        </div> */}
        <Typography variant="h5" color="gray" align="center" style={{marginTop:"30px"}}>
          Tiatro App. 
        </Typography>
        </Box>
    </Box>
    </Box>
  </div>

  );
}
