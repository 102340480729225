import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setAll } from "../../helpers/set-all";

const getPersonCoinThumbnail = async (PersonCoin) => {
    let headersList = {
        "api-key": process.env.REACT_APP_API_KEY,
    };
    const data = await axios
        .get(process.env.REACT_APP_API_ASSETS_BASE_URL + `image/${PersonCoin.coinAsset._id}?m=thumbnail&h=200&q=200&o=0`, {
            headers: headersList,
        });

    return data.data;
}

export const loadStyle = createAsyncThunk("style/loadStyle", async () => {
    const site = `"CustomerSite":'${process.env.REACT_APP_SITE}'`;
    let headers = {
        "api-key": process.env.REACT_APP_API_KEY,
    };
    const data = await axios
        .get(process.env.REACT_APP_API_BASE_URL + "item/HomePageCollection", {
            headers: headers,
            params: { filter: `{${site}}` },
        });
    const Coins = data.data.Coins;
    if (Coins && Coins["repeatCoins"] !== null) {
        for (const PersonCoin of Coins["repeatCoins"]) {
            const thumbnailURL = await getPersonCoinThumbnail(PersonCoin);
            PersonCoin.thumbnailURL = thumbnailURL;
          }
    }
    return data.data;
});

const styleSlice = createSlice({
    name: "style",
    initialState: {
        loading: true
    },
    reducers: {
        fetchStyleSuccess(state, action) {
            setAll(state, action.payload);
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadStyle.pending, state => {
                state.loading = true;
            })
            .addCase(loadStyle.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadStyle.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
    }
});

export default styleSlice.reducer;

export const { fetchStyleSuccess } = styleSlice.actions;