// application global constants and defaults
// console.log("handcash",process.env.HANDCASH_PUBLIC_KEY)
// console.log("handcash2",process.env.REACT_APP_HANDCASH_PUBLIC_KEY)
const constants = {
    APP_NAME:'Tiatro',
    //"https://openmiclive.stream/api"
    API_URL: 'https://tiatro.app/api',
    OPENMIC_URL: 'https://api.openmiclive.stream/',
    OPENMIC_PAYMAIL: "openmic@moneybutton.com",
    LOCAL_TOKEN_STREAMER: 'openmicToken',
    PLATFORM_PAYMAIL: "openmic@moneybutton.com",
    KEY_TOKEN: "hc-token",
    KEY_USER: "hc-user",
    KEY_PUB: "hc-mic-publish",
    KEY_SUB: "hc-mic-subscribe",
    // local
    APP_HANDCASH: process.env.REACT_APP_HANDCASH_PUBLIC_KEY,
    // production
    //APP_HANDCASH: "621e839bdaf69462bc141cd1",
    OPENMIC_APP: "621e839bdaf69462bc141cd1",
    OPENMIC_LOCAL: "621e84baeb8367f22a8312e7",
}

export {constants}