import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import Navbar from "../../Component/Navbar/Navbar";
import CategoryVideos from "../../Component/CategoryVideos/CategoryVideos";
import CoinWithoutImg from "../../Component/CoinWithoutImg/CoinWithoutImg";
import CategoryList from "../../Component/CategoryList/CategoryList";
import { loadVideoWithCategory } from "../../store/slices/video-slice";
import './category.css';
const Category = () => {
    const { category } = useParams();
    const dispatch = useDispatch();
    const siteStyle = useSelector(state => state.style);
    useEffect(() => {
      // const categoryType = category.charAt(0).toUpperCase() + category.slice(1);
      const categoryType = category.toUpperCase();
      dispatch(loadVideoWithCategory({categoryType}));
      document.title = `Category| ${category}`;
    }, [category]);
  
    return (
      <>
        
        <div
          className=""
          style={{ background: siteStyle.BodyBackgroundColor, padding: "0.5rem" }}
        >
          <div className="category-container mx-auto px-4">
            <Navbar />
            <CoinWithoutImg />
            <CategoryList />
            <CategoryVideos/>
          </div>
        </div>
      </>
    );
  };
  
  export default Category;