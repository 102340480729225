import React, { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../../Component/Navbar/Navbar";
import Coin from "../../Component/Coin/Coin";
import PersonCoin from "../../Component/PersonCoin/PersonCoin";
import CoinWithoutImg from "../../Component/CoinWithoutImg/CoinWithoutImg";
import { loadSelectedPersonInfo } from "../../store/slices/person-slice";
import { formatted_bio } from "../../helpers/formatted_bio";
import './PersonDetail.css';

const PersonDetail = () => {
  const { name, id } = useParams();
  const dispatch = useDispatch();
  const siteStyle = useSelector(state => state.style);
  const isLoading = useSelector(state => state.person.selected_loading);
  const person = useSelector(state => state.person.selected);
  const allVideo = useSelector(state => state.video.all);
  
  useEffect(() => {
    dispatch(loadSelectedPersonInfo({ name }));
    document.title = `person|${name}`;
  }, [name]);

  return (
    <>
      <div
        className=""
        style={{ background: siteStyle.BodyBackgroundColor, padding: "0.5rem" }}
      >
        <div className="select-person-container">
          <Navbar />
          <CoinWithoutImg />
          <Coin />
          <div className="person-detail-container">

            {!isLoading && (
              <>
                {/* <Link className="back-link" to="/">
                  <svg viewBox="0 0 16 16" astro-icon="bi:chevron-left"><path fill="currentColor" fillRule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"></path></svg>
                </Link> */}
                <div className="peron-detail-info">

                  <div className="person-detail-image">
                    <img
                      src={process.env.REACT_APP_IMAGE_PATH + person.picture.path}
                      alt={process.env.REACT_APP_IMAGE_PATH + person.picture.title}
                    />

                  </div>
                  <div>
                    <div className="person-header">
                      <div className="person-header-title-badge">
                        <p className="text-white text-uppercase font_family m-0 mt-1 mb-1">
                          {person.name}
                        </p>
                        <div className="flex">
                          {person.role && person.role
                            .filter(data => data !== 'IMDb' && data !== 'IMDB')
                            .map((data, i) => (
                              <PersonCoin coin={data} key={i} />
                            ))}
                        </div>
                      </div>

                      {person.IMDB &&
                        <div className="flex mb-3">
                          <a href={person.IMDB} target="_blank">
                            <img
                              className="w-8 md:w-11 lg:w-16"
                              src="/assets/imdb.png"
                              alt={person.IMDB}
                            />
                          </a>
                        </div>}
                    </div>

                    <p className="text-white pb-3 text-xs md:text-sm lg:text-base">
                      {person.shortbio
                        ? formatted_bio(person.shortbio, 0)
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="videos-container">
                  {allVideo.map((videos) =>
                    videos.Person && videos.Person.length > 0
                      ? videos.Person.map((persons) =>
                        persons._id === id ? (
                          <div
                            key={videos._id}
                            className="video-card"
                          >
                            <Link to={'/video/' + videos._id} title={videos.Title} key={videos._id}>
                              <div>
                                <img
                                  src={
                                    process.env.REACT_APP_IMAGE_PATH +
                                    videos.PosterImage.path
                                  }
                                  alt={videos.PosterImage.title}
                                  className="video-card-img"
                                />
                              </div>
                              <div className="text-white">
                                <p className="text-uppercase font_family title">{videos.Title}</p>
                                <p className="video-card-description description-color">
                                  {formatted_bio(videos.Description, 0)}
                                </p>
                              </div>
                            </Link>
                          </div>
                        ) : (
                          ""
                        )
                      )
                      : ""
                  )}
                </div>
                <div className="person-detail-background-div" style={{ "--context-color": person.picture.colors[0] }}>
                  <img
                    src={
                      person.picture
                        ? process.env.REACT_APP_IMAGE_PATH + person.picture.path
                        : ""
                    }
                    alt={
                      person.picture
                        ? process.env.REACT_APP_IMAGE_PATH + person.picture.title
                        : ""
                    }
                  />
                </div>
              </>
            )}

            {isLoading && <h1 className="noData">Loading...</h1>}
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonDetail;