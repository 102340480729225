import React, { useState, useEffect, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import ControlPanel from './ControlPanel';
import './PDFReader.css';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFReader = ({ file, title }) => {
  const [scale, setScale] = useState(1.2);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setScale(0.7);
      } else {
        setScale(1.3);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleIframe = () => {
    setShowIframe(!showIframe);
  };

  const toggleFullscreen = () => {
    if (!isFullscreen) {
      if (containerRef.current.requestFullscreen) {
        containerRef.current.requestFullscreen();
      } else if (containerRef.current.mozRequestFullScreen) {
        containerRef.current.mozRequestFullScreen();
      } else if (containerRef.current.webkitRequestFullscreen) {
        containerRef.current.webkitRequestFullscreen();
      } else if (containerRef.current.msRequestFullscreen) {
        containerRef.current.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
  };

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullscreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullscreenChange);
    };
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div ref={containerRef} className={isFullscreen ? 'fullscreen' : ''}>
      <div className="pdf-container">
        <div className="ample-pdf-title-wrapper">
          <h3 className="text-3xl font-semibold text-black text-center ample-pdf-title">
            {title}
          </h3>
        </div>
        <ControlPanel
          scale={scale}
          setScale={setScale}
          numPages={numPages}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          toggleFullscreen={toggleFullscreen}
          toggleIframe={toggleIframe}
          isFullscreen={isFullscreen}
        />
        <div className="pdf-content">
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} scale={scale} />
          </Document>
        </div>
      </div>
      {showIframe && (
        <div className="iframe-container">
          <iframe src={file} width="100%" height="100%" />
          <button className="close-iframe" onClick={toggleIframe}>Exit Fullscreen</button>
        </div>
      )}
      <style jsx>{`
        .ample-zan-pdf-wrapper .react-pdf__Page__textContent.textLayer {
          display: none !important;
        }

        .ample-zan-pdf-wrapper .fullscreen .react-pdf__Page {
          min-height: 100vh !important;
        }

        .ample-pdf-title-wrapper {
          background-color: #d7d7d7;
          width: 100% !important;
          padding: 10px 0;
          min-height: 60px;
        }

        .ample-zan-pdf-wrapper {
          max-width: 90%;
          margin: auto;
        }

        .loader {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        
        .pdf-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          // min-width: 450px;
          height: 670px;
          overflow: hidden;
        }

        .ample-pdf-title {
          margin-bottom: 10px;
        }

        .pdf-content {
          flex: 1;
          width: 100%;
          overflow: auto;
        }
        
        @media (max-width: 768px) {
          .pdf-container {
            max-width: 90%;
            margin: auto;
            height: 80vh;
          }

          .ample-pdf-title {
            font-size: 20px;
          }
            
          .ample-pdf-control-panel {
              margin-top: 0;
              margin-bottom: 0 !important;
              background: #dee2e6 !important;
              width: 100%;
          }

          .ample-pdf-control-panel .control-panel .d-flex span {
              font-size: 13px;
          }

          .ample-pdf-close-btn-wrapper {
              display: block;
              text-align: center;
              padding: 0 !important;
          }
        }
        
        .control-panel {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1rem;
          padding: 1rem;
          background-color: #f8f9fa;
          border-radius: 5px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }
        
        .control-panel button {
          background: none;
          border: none;
          cursor: pointer;
          padding: 0;
          margin: 0;
        }
        
        .control-panel button.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }
        
        .control-panel button.clickable {
          cursor: pointer;
          opacity: 1;
        }
        
        .control-panel .zoom-out,
        .control-panel .zoom-in {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        
        .control-panel svg {
          width: 24px;
          height: 24px;
        }
        
        .control-panel span {
          margin: 0 1rem;
          font-size: 1rem;
        }

        .fullscreen .pdf-container {
          height: 100vh;
          width: 100vw;
        }

        .fullscreen .control-panel {
          position: fixed;
          top: 50px; /* Adjust this as needed */
          left: 50%;
          transform: translateX(-50%);
          z-index: 1000;
        }

        .fullscreen .ample-pdf-title {
          position: fixed;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1000;
        }

        .iframe-container {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.8);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .iframe-container iframe {
          border: none;
        }

        .iframe-container .close-iframe {
          position: absolute;
          bottom: 25px;
          right: 25px;
          background: #ff0000;
          color: #fff;
          border: none;
          padding: 10px 20px;
          cursor: pointer;
          z-index: 1001;
        }

        @media (max-width: 768px) {
          .iframe-container .close-iframe {
            bottom: 25px;
            right: 25px;
            padding: 5px 10px;
          }
        }
      `}</style>
    </div>
  );
};

export default PDFReader;
