import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import './Header.css';


const Header = () => {
  const siteStyle = useSelector(state => state.style);
  const Coins = siteStyle.Coins;
    return (
    <>
      <h5 className="text-white text-start text-uppercase mb-0 font_family mx-4">
        Talent Directory
      </h5>
      <Swiper
        style={{ "--swiper-pagination-bullet-inactive-opacity": "1" }}
        slidesPerView={"7"}
        spaceBetween={5}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper home-coins"
        navigation={{ disabledClass: 'disabled_swiper_button' }}
        
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          // when window width is >= 530px
          530: {
            slidesPerView: 3,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 4,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 6,
          },
          // when window width is >= 1440px
          1440: {
            slidesPerView: 7,
          },
          // when window width is >= 1800px
          1800: {
            slidesPerView: 9,
          },
        }}
      >
        {Coins && Coins["repeatCoins"] !== null
          ? Object.entries(Coins["repeatCoins"]).map(([k, v], ind) =>
            v !== null ? (
              <SwiperSlide key={ind}>
                <Link to={"person/" + v.coinLabel.toLowerCase()}>
                  <img
                    src={v.thumbnailURL}
                    alt={v.coinLabel}
                    className="mx-auto w-16 md:w-32 lg:w-48 person-coin"
                  />
                </Link>
                {/*} <Link to={"person/" + v.coinLabel.toLowerCase() + "/horror"}>
                    <p className="text-white text-center text-uppercase m-0 font_family">
                    {v.coinLabel}
                    </p>
                  </Link>*/}
              </SwiperSlide>
            ) : (
              ""
            )
          )
          : ""}
      </Swiper>
    </>
  );
};

export default Header;
