import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { setAll } from "../../helpers/set-all";

export const loadPersonInfo = createAsyncThunk("person/loadPersonInfo", async ({ label }) => {
    let headers = {
        "api-key": process.env.REACT_APP_API_KEY,
    };
    const filter = { filter: `{ "role":'${label}'}` };
    const data = await axios.get(process.env.REACT_APP_API_BASE_URL + "items/PersonCollection", {
        headers: headers,
        params: filter,
    })
    return {
        all: data.data
    };
});

export const loadAllPersonInfo = createAsyncThunk("person/loadAllPersonInfo", async () => {
    let headers = {
        "api-key": process.env.REACT_APP_API_KEY,
    };
    const data = await axios.get(process.env.REACT_APP_API_BASE_URL + "items/PersonCollection", {
        headers: headers
    })
    return {
        allPersonInfo: data.data
    };
});

export const loadSelectedPersonInfo = createAsyncThunk("person/loadSelectedPersonInfo", async ({ name }) => {
    let headers = {
        "api-key": process.env.REACT_APP_API_KEY,
    };
    const filter = { filter: `{ "name":'${name}'}` };
    const data = await axios.get(process.env.REACT_APP_API_BASE_URL + "items/PersonCollection", {
        headers: headers,
        params: filter,
    })
    return {
        selected: data.data[0]
    };
});


const personSlice = createSlice({
    name: "person",
    initialState: {
        loading: true,
        selected_loading: true,
        all_loading: true,
    },
    reducers: {
        fetchPersonSuccess(state, action) {
            setAll(state, action.payload);
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadPersonInfo.pending, state => {
                state.loading = true;
            })
            .addCase(loadPersonInfo.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadPersonInfo.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
            .addCase(loadSelectedPersonInfo.pending, state => {
                state.selected_loading = true;
            })
            .addCase(loadSelectedPersonInfo.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.selected_loading = false;
            })
            .addCase(loadSelectedPersonInfo.rejected, (state, { error }) => {
                state.selected_loading = false;
                console.log(error);
            })
            .addCase(loadAllPersonInfo.pending, state => {
                state.all_loading = true;
            })
            .addCase(loadAllPersonInfo.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.all_loading = false;
            })
            .addCase(loadAllPersonInfo.rejected, (state, { error }) => {
                state.all_loading = false;
                console.log(error);
            })
    }
});

export default personSlice.reducer;

export const { fetchPersonSuccess } = personSlice.actions;
