import { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';

const CheckLogin = () => {
    const navigate = useNavigate();
    useEffect(() => {
        // Navigate immediately
        navigate("/", { state: { fromCheckLogin: true } });
    }, [navigate]);

    return null; // No need to render anything here
};

export default CheckLogin;
