import React, { useEffect, useState } from "react";
import axios from "axios";
import './TopLink.css';

const test_data = [{ title: 'usa', _id: 0 }, { title: 'africa', _id: 1 }, { title: 'uk', _id: 2 }, { title: 'canada', _id: 3 }, { title: 'korea', _id: 4 }]
const TopLink = ({ countryFilter, country }) => {
  const [countryList, setCountryList] = useState([]);

  useEffect(() => {
    setCountryList(test_data);
    // countryHandler();
  }, []);

  const countryHandler = async () => {
    let headersList = {
      "api-key": process.env.REACT_APP_API_KEY,
    };

    await axios
      .get(process.env.REACT_APP_API_BASE_URL + "item/Country", {
        headers: headersList,
      })
      .then((data) => setCountryList([data.data]))
      .catch((error) => console.log(error));
  };

  return (
    <ul className="country nav nav-tabs border-0 font_family mb-4">
      {countryList.map((data) => (
        <li className="nav-item m-0" key={data._id}>
          <button className={`btn-lg nav-link text-white text-center text-uppercase font_size ${country === data.title ? "activeCountry" : ""
            }`} aria-current="page" onClick={() => {
              countryFilter(data.title);
            }}>{data.title}</button>
        </li>
      ))}
    </ul>
  );
};

export default TopLink;
