import { useContext } from "react";
import { Modal, Button } from "react-bootstrap";
import { auth, facebook, google } from "../../utils/firebase";
import {
	FacebookLoginButton,
	GoogleLoginButton,
} from "react-social-login-buttons";
import { signInWithPopup, signOut,getIdToken  } from "firebase/auth";
import { UserContext } from "../../context/UserContext";
import { TokenContext } from "../../context/TokenContext";
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import Cookies from 'js-cookie';

const LoginModal = (props) => {
    const {user, setUser} = useContext(UserContext);
    const {token, setBCSToken} = useContext(TokenContext);
    const navigate = useNavigate();

	const login = async (provider) => {
		try {
			const result = await signInWithPopup(auth, provider);
            const token = await getIdToken(result.user);
            // Save the token as a cookie
        Cookies.set('socialUser', JSON.stringify(result.user), { expires: 30 }); // Expires in 1 day
        Cookies.set('socialToken', token, { expires: 7 }); // Expires in 1 day
			setUser(result.user);
			setBCSToken(token);
            props.onHide(); 
            navigate('/');
            
		} catch (e) {
			console.log("login error ", e);
		}
	};

    const logout = async () => {
        try {
          await signOut(auth);
          setUser(null);
          setBCSToken(null);
          Cookies.remove("socialToken")
          Cookies.remove("socialUser")
          Cookies.remove("hcToken")
        } catch (e) {
          console.log(e);
        }
    };

	return (
        !user ? 
		<Modal
			{...props}
			size="md"
			aria-labelledby="contained-modal-title-vcenter"
			centered
		>
			<Modal.Header closeButton>
				<Modal.Title id="contained-modal-title-vcenter">
					Social Authentication SignIn
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
                <div className="d-flex flex-column align-items-center">
                    <GoogleLoginButton
                        style={{ width: 250 }}
                        onClick={() => login(google)}
                    />
                    <FacebookLoginButton
                        style={{ width: 250 }}
                        onClick={() => login(facebook)}
                    />
                </div>
			</Modal.Body>
			<Modal.Footer>
				<Button variant="outline-danger" onClick={props.onHide}>Close</Button>
			</Modal.Footer>
		</Modal> : 
        <Modal
            {...props}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Social Authentication SignOut
                </Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="outline-danger" onClick={() => {logout(); navigate("/")}}>SignOut</Button>
            </Modal.Footer>
        </Modal>
	);
};

export default LoginModal;
