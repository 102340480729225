import React, { useEffect, useState } from "react";
import axios from "axios";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";
import { Link, useParams } from "react-router-dom";
const CategoryList = () => {
  const [CategoryList, setCategoryList] = useState([]);
  const { category } = useParams();
  const categoryType = category ? (category.charAt(0).toUpperCase() + category.slice(1)) : "";
  useEffect(() => {
    CategoryListHandler();
  }, []);
  const CategoryListHandler = async () => {
    let headersList = {
      "api-key": process.env.REACT_APP_API_KEY,
    };

    await axios
      .get(process.env.REACT_APP_API_BASE_URL + "tree/" + process.env.REACT_APP_SITE_CATEGORY, {
        headers: headersList,
      })
      .then((data) => setCategoryList(data.data))
      .catch((error) => console.log(error));
  };
  return (
    <>
      <Swiper
        style={{ "--swiper-pagination-bullet-inactive-opacity": "1" }}
        slidesPerView={"7"}
        spaceBetween={5}
        pagination={{
          clickable: true,
        }}
        modules={[Pagination, Navigation]}
        className="mySwiper home-coins"
        navigation={true}
        breakpoints={{
          320: {
            slidesPerView: 2,
          },
          // when window width is >= 530px
          530: {
            slidesPerView: 3,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 4,
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 6,
          },
          // when window width is >= 1440px
          1440: {
            slidesPerView: 7,
          },
          // when window width is >= 1800px
          1800: {
            slidesPerView: 9,
          },
        }}
      >
        {CategoryList.map((data) => (
          <SwiperSlide key={data._id}>
            <Link to={"/category/" + data.Title.toLowerCase()}>
              <p className={`text-white text-center text-uppercase font_family m-0 ${categoryType === data.Title ? "active" : ""
                }`}>
                {data.Title}
              </p>
            </Link>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default CategoryList;
