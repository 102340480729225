import { useEffect, useRef, useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
//import { useRouter } from "next/router";
import HandcashClient from "./HandcashClient";
// import {useStore} from './useGlobalState'
import { TokenContext } from "../../context/TokenContext";

//used to indicate the video to purchase
const MEDIA_ID = "";
export default function ButtonLogin(props /*: any*/) {
  const {bcsToken, setBCSToken} = useContext(TokenContext);

  const [action, setAction] = useState("Login");
  // const [balance, setBalance] = useState(null);
  const handcash = new HandcashClient()
  //const handcash = useRef<HandcashClient>();
  //const router = useRouter()
  const navigate = useNavigate();
  //const {logout} = useStore()

  useEffect(() => {
    // handcash.current = new HandcashClient("");
    if (handcash.getMoneyStreamToken()) {
      setAction("Logout");
    }

    // async function getbalance() {
    //     if (handcash.current.islinked) {
    //         try {
    //             const bal = await handcash.current.getbalance()
    //             console.log(`BALANCE`, bal)
    //             //{"spendableSatoshiBalance":180146,"spendableFiatBalance":0.3195,"currencyCode":"USD"}
    //             setAction('Fund')
    //             setBalance(bal.spendableFiatBalance)
    //         } catch (err) { console.error(err) }
    //     }
    // }
    // getbalance()
  }, []);

  async function connectWithHandCash() {
    // handcash.current = new HandcashClient("");
    if (handcash.getMoneyStreamToken()) {
      handcash.clearToken();
      handcash.clearUser();
      setBCSToken(null)
      //logout();
      setAction("Login");
      navigate("/");
    } else {
      const url = await handcash.appApproveUrl(MEDIA_ID);
      console.log(`handcash`, url);
      window.location.href = url || "/";
    }
  }

  return (
    <div id="connectButton" onClick={connectWithHandCash}>
      <span>{action}</span>
    </div>
  );
}
