import React, { useContext, useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import "./Navbar.css";
import {Button} from 'react-bootstrap';
import LoginModal from "../SocialLogin/LoginModal";
import { UserContext } from "../../context/UserContext";
import ButtonLogin from "../handcash/ButtonLogin";
import Cookies from 'js-cookie';


const Navbar = () => {
	const siteStyle = useSelector((state) => state.style);
  	const [modalShow, setModalShow] = useState(false);
	const { user,setUser } = useContext(UserContext);
    useEffect(() => {
        // console.log({ user });
        // Check if user is null and socialUser token exists
        if (user == null) {
            const socialUserToken = Cookies.get('socialUser');
            if (socialUserToken) {
                try {
                    // Assuming the token is a JSON string you want to parse
                    const parsedUser = JSON.parse(socialUserToken);
                    setUser(parsedUser);
                } catch (error) {
                    console.error('Error parsing socialUser token:', error);
                }
            }
        }
    }, [user]);

	return (
		<div className="container" key={siteStyle._id}>
			<div className="navBarDiv" style={{ color: siteStyle.SidebarFontColor }}>
				<div className="align-items-center flex justify-content-between">
					<div className="">
						<Link to="/">
							<img
								src={
									siteStyle.NavLogo
										? process.env.REACT_APP_IMAGE_PATH + siteStyle.NavLogo.path
										: ""
								}
								className="logo"
								alt={
									siteStyle.NavLogo
										? process.env.REACT_APP_IMAGE_PATH + siteStyle.NavLogo.title
										: ""
								}
							/>
						</Link>
					</div>
					{/* <Button variant="outline-light" onClick={() => setModalShow(true)}>{user ? user.displayName : "Social Login"}</Button> */}
					<ButtonLogin />
				</div>
			</div>
      		<LoginModal show={modalShow} onHide={() => setModalShow(false)} />
		</div>
	);
};

export default Navbar;
