import React from "react";
import { Link } from "react-router-dom";
import PersonCoin from "../PersonCoin/PersonCoin";
import { formatted_bio } from "../../helpers/formatted_bio";
import "./PersonListItem.css";
import { useSelector } from "react-redux";

export const PersonListItem = ({ person, category }) => {
  const Coins = useSelector(state => state.style.Coins);
    
  const getCategory = () => {
    if(category) {
      return category;
    }

    const categoryExists = (category) => {
      return Coins['repeatCoins'].some(function(el) {
        return el.coinLabel === category.toUpperCase();
      }); 
    }

    for (let index = 0; index < person.role.length; index++) {
      const role_item = person.role[index];
      if(categoryExists(role_item)) {
        return role_item.toLowerCase()
      } 
      
    }


  }

  return (
    <div className="person-card">
      <div className="person-img-div">
        <Link to={`/person/${getCategory()}/${person._id}/${person.name}`} state={{ person }}>
          <img
            className="w-44 md:w-36 lg:w-48"
            src={process.env.REACT_APP_IMAGE_PATH + person.picture.path}
            alt={person.picture.title}
          />
        </Link>
      </div>
      <div className="person-detail">
        <div className="person-detail-title-badge">
          <p className="text-white text-center text-uppercase font_family">
            {person.name}
          </p>
          <div className="flex sm:justify-start justify-center">
            {person.role &&
              person.role
                .filter(person => person !== 'IMDb' && person !== 'IMDB')
                .map((value, i) => (
                  <PersonCoin coin={value} key={i} />
                ))}
          </div>
        </div>

        <div className=" text-xs md:text-sm lg:text-base person-description">
          <p className="description-color">
            {formatted_bio(person.shortbio)}
          </p>
          <Link to={`/person/${getCategory()}/${person._id}/${person.name}`} state={{ person }}>
            <p className="text-read-more"><u>Read more</u></p>
          </Link>

        </div>
      </div>
    </div>
  );
}