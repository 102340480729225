//import eventbus from "./eventbus";
//import { HandCashConnect } from '@handcash/handcash-connect'
import HandcashApiClient from "./handcash-client"
import {constants} from '../../types/constants'
// import {events} from '../../../../types/events'
// import { useStore } from "./useGlobalState";
import Cookies from 'js-cookie';

const KEY_TOKEN = "hc-token";
const KEY_USER = "hc-user";
// const {purchase} = useStore()

//testing in hc prod '6029ee92672a260c4da21b96'
//const PRODUCTION = Environments.prod
const CURRENTENV = constants.APP_HANDCASH;

// Direct client bypasses api
// See HandcashApiClient for api version
export default class HandcashClient {
  MIN_BALANCE = 3000;
  msaddress;
  // private _app = 'moneystream'
  _balance= null;
  constructor(msaddress) {
    //address is required for pay method!
    // if (msaddress === undefined)
    //   throw Error(`msaddress undefined!`);
    this.msaddress = msaddress;
  }
  get islinked() {
    return this.getMoneyStreamToken() !== null;
  }
  get balance() {
    return this._balance;
  }
  set balance(val) {
    this._balance = val;
  }
  reduceBalance(amount /*: number*/) {
    if (this.balance?.spendableFiatBalance) {
      this.balance.spendableFiatBalance -= amount;
    }
  }
  get hasBalanceAvailable() {
    return (
      this._balance && this._balance?.spendableSatoshiBalance > this.MIN_BALANCE
    );
  }
  //for now, moneystream token is the handcash token
  getMoneyStreamToken() {
    return Cookies.get(KEY_TOKEN);
  }
  storeToken(token/*: string*/) {
    localStorage.setItem(KEY_TOKEN, token);
    Cookies.set(KEY_TOKEN, token, { expires: 7 }); // Expires in 1 day

  }
  clearToken() {
    localStorage.removeItem(KEY_TOKEN);
    Cookies.remove(KEY_TOKEN);
    
  }
  storeUser(profile/*: any*/) {
    localStorage.setItem(KEY_USER, JSON.stringify(profile));
  }
  clearUser() {
    localStorage.removeItem(KEY_USER);
  }

  // getAppId(appNameOrId: string ='moneystream') {
  //     if (appNameOrId.toUpperCase() === "MONEYSTREAM") {
  //         // console.log(`APPNAME`, appNameOrId, MONEYSTREAM_APP)
  //         return MONEYSTREAM_APP
  //     }
  //     return appNameOrId
  // }
  // getApp() {
  //     const hcappid = this.getAppId()
  //     const hcapp = new HandCashConnect(hcappid, CURRENTENV)
  //     return hcapp
  // }
  // //appid and token
  // getAccount():any {
  //     const hcapp = this.getApp()
  //     const account = hcapp.getAccountFromAuthToken(this.getMoneyStreamToken())
  //     return account
  // }

  async appApproveUrl(productid /*?: string*/) {
    // const hcapp = this.getApp()
    // const redirectUrl =  hcapp.getRedirectionUrl()
    const hcapi = new HandcashApiClient(this.msaddress);
    let redirectUrl = await hcapi.appApproveUrl();
    if (productid) redirectUrl += `&pid=${productid}`;
    console.log(`HC_REDIRECT`, redirectUrl);
    return redirectUrl;
  }

  async getbalance()/*: Promise<any>*/ {
    const hcapi = new HandcashApiClient(this.msaddress);
    return hcapi.getbalance();
    //{"spendableSatoshiBalance":180146,"spendableFiatBalance":0.3195,"currencyCode":"USD"}
  }

  //process a payment
  // programEvent = {
  //       id: id++,
  //       title: `Hour ${hour} Segment A`,
  //       allDay: false,
  //       start: moment().hours(hour).minutes(0).toDate(),
  //       end: moment().hours(hour).minutes(29).toDate(),
  //     }
  // role = {programid, permission: publish/subscribe, action:tip/view/buy}
  async pay(config/*: any*/, programEvent/*:any*/, role/*?:any*/) {
    if (!config.payto) {
      throw new Error(`HandcashClient.tsx: payto address missing!`);
    }
    const hcapi = new HandcashApiClient(this.msaddress);
    const payment = await hcapi.pay(config);
    this.storePayment(payment, programEvent, role, config)
    return payment
  }

  //TODO: store in api/db
  storePayment(payment/*:any*/, programEvent/*:any*/, role/*:any*/, config/*:any*/) {
    if (payment?.message) {
      console.error(`HANDCASH ERROR`, payment, config)
    } else if (payment?.code) {
      console.error(`HANDCASH ERROR`, payment)
    } else {
      // PAYMENT SUCCESS HERE. STORE IT
      // this stores it in user.purchases!!!
      //purchase(payment, programEvent,role)
      const paymentload = [{payment,program:programEvent,role}]
      console.log(`PAYMENTLOAD`, paymentload)
      if (role) {
        if (role.permission==="pub") {
          localStorage.setItem(constants.KEY_PUB, JSON.stringify(paymentload));
        } else {
          localStorage.setItem(constants.KEY_SUB, JSON.stringify(paymentload));
        }
      } else {
        // assume payment was for a purchase to view
        localStorage.setItem(constants.KEY_SUB, JSON.stringify(paymentload));
      }
      //eventbus.dispatch("appEvent", { command:events.payment, data:paymentload });
    }

  }

  async profile() {
    const hcapi = new HandcashApiClient(this.msaddress);
    return hcapi.profile();
  }
}
