import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from 'react-router-dom';
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import required modules
import { Pagination, Navigation } from "swiper";
import { useSelector } from "react-redux";
import './HomeVideoCabinets.css';

const HomeVideoCabinets = ({ country }) => {
  const category = useSelector(state => state.category.all);
  return (
    <>
      {category.map((data, ind) => (
        <Category data={data} country={country} ind={ind} key={ind}/>
        )
      )}
    </>
  );
};

const Category = ({data, country, ind}) => {
  const [backgroundColor, setBackgroundColor] = useState("#18181b");
  const allVideoCabinet = useSelector(state => state.cabinet.all);
  // console.log("allVideoCabinet", allVideoCabinet);
  const handleMouseOver = (cabinets) => {
    setBackgroundColor(cabinets.PosterImage.colors[0])
  }
  const handleMouseOut = () => {
    setBackgroundColor("#18181b")
  }
  return (
    <div
          className="row flex home-videos align-items-center from-zinc-900 bg-gradient-to-t"
          key={data._id}
          style={{backgroundColor: backgroundColor, borderRadius: "0.5rem", margin: "0.5rem", padding: "0.5rem", transitionDuration:"1s"}}
        >
          <h5 className="text-white text-start text-uppercase font_family p-0 mb-0">
            <i> {data.label}</i>
          </h5>
          <Swiper
            style={{ "--swiper-pagination-bullet-inactive-opacity": "1" }}
            spaceBetween={15}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination, Navigation]}
            className="mySwiper"
            navigation={true}
            breakpoints={{
              399: {
                slidesPerView: 1,
              },
              // when window width is >= 530px
              400: {
                slidesPerView: 2,
              },
              // when window width is >= 768px
              640: {
                slidesPerView: 3,
              },
              // when window width is >= 1024px
              1024: {
                slidesPerView: 4,
              },
              // when window width is >= 1440px
              1280: {
                slidesPerView: 5,
              },
              // when window width is >= 1800px
              1536: {
                slidesPerView: 6,
              },
            }}
          >
            {allVideoCabinet.map((cabinets) =>
              cabinets.Country && cabinets.Country.length > 0
                ? cabinets.Country.map((Country) =>
                  Country.toLowerCase() === country.toLowerCase() &&
                  cabinets.CategoryVideo && cabinets.CategoryVideo.length > 0 &&
                  cabinets.CategoryVideo.map((cat) =>
                    cat._id === data._id ? (
                      <SwiperSlide key={ind} onMouseOver={() => handleMouseOver(cabinets)} onMouseOut={()=>handleMouseOut()}>
                        <Link to={'/cabinet/' + cabinets._id} title={cabinets.Name} key={ind}>
                          <div className="d-flex align-items-center justify-center align-content-center p-3">
                            <img
                              src={process.env.REACT_APP_IMAGE_PATH + cabinets.PosterImage.path}
                              alt={cabinets.PosterImage.title}
                              title={cabinets.Name}
                              className="hipHop_slide-image"
                            />
                          </div>
                          <p className="px-3 pt-0 pb-3">{cabinets.Name}</p>
                        </Link>
                      </SwiperSlide>
                    ) : ("")
                  )
                ) : ""
            )}
          </Swiper>
        </div>
  )
}

export default HomeVideoCabinets;
