import React, { useState } from "react";
import { useSelector } from "react-redux";
import './CategoryVideos.css';
const CategoryVideos = () => {
  const [backgroundColor, setBackgroundColor] = useState("#18181b");
  const isLoading = useSelector(state =>  state.video.categoryLoading);
  const video = useSelector(state =>  state.video.category);
  const handleMouseOver = (videos) => {
    setBackgroundColor(videos.PosterImage.colors[0])
  }
  const handleMouseOut = () => {
    setBackgroundColor("#18181b")
  }
  return (
    <div className="category-video-container" style={{backgroundColor: backgroundColor}}>
      {isLoading ? (
        <h1 className="noData">Loading...</h1>
      ) : (video.length === 0 && (
        <h1 className="noData">Data not found...</h1>)
      )}
      {!isLoading && video.map((data) => (
        <div
          key={data._id}
          className="category-video-card"
          onMouseOver={() => handleMouseOver(data)}
          onMouseOut={() => handleMouseOut()}
        >
          <img
            src={process.env.REACT_APP_IMAGE_PATH + data.PosterImage.path}
            alt={data.PosterImage.title}
          />
        </div>
      ))}
    </div>
  );
};

export default CategoryVideos;
