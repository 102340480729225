import React from 'react';

const ControlPanel = ({
  scale,
  setScale,
  numPages,
  pageNumber,
  setPageNumber,
  toggleIframe,
  toggleFullscreen,
  isFullscreen
}) => {
  const isFirstPage = pageNumber === 1;
  const isLastPage = pageNumber === numPages;

  const firstPageClass = isFirstPage ? 'disabled' : 'clickable';
  const lastPageClass = isLastPage ? 'disabled' : 'clickable';

  const goToFirstPage = () => {
    if (!isFirstPage) setPageNumber(1);
  };
  const goToPreviousPage = () => {
    if (!isFirstPage) setPageNumber(pageNumber - 1);
  };
  const goToNextPage = () => {
    if (!isLastPage) setPageNumber(pageNumber + 1);
  };
  const goToLastPage = () => {
    if (!isLastPage) setPageNumber(numPages);
  };

  const isMinZoom = scale < 0.6;
  const isMaxZoom = scale >= 2.0;

  const zoomOutClass = isMinZoom ? 'disabled' : 'clickable';
  const zoomInClass = isMaxZoom ? 'disabled' : 'clickable';

  const zoomOut = () => {
    if (!isMinZoom) setScale(scale - 0.1);
  };

  const zoomIn = () => {
    if (!isMaxZoom) setScale(scale + 0.1);
  };

  return (
    <>
      <div className="ample-pdf-control-panel control-panel page-control mb-3 p-3 d-flex align-items-center justify-content-between">
        <button className={`fas fa-backward mx-3 ${firstPageClass}`} onClick={goToPreviousPage}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953l7.108-4.062A1.125 1.125 0 0121 8.688v8.123zM11.25 16.811c0 .864-.933 1.405-1.683.977l-7.108-4.062a1.125 1.125 0 010-1.953L9.567 7.71a1.125 1.125 0 011.683.977v8.123z" />
          </svg>
        </button>
        <span>
          Page {pageNumber} of {numPages}
        </span>
        <button className={`fas fa-forward mx-3 ${lastPageClass}`} onClick={goToNextPage}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M3 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062A1.125 1.125 0 013 16.81V8.688zM12.75 8.688c0-.864.933-1.405 1.683-.977l7.108 4.062a1.125 1.125 0 010 1.953l-7.108 4.062a1.125 1.125 0 01-1.683-.977V8.688z" />
          </svg>
        </button>
      </div>
      <div className="ample-pdf-control-panel control-panel zoom-control mb-3 p-3 d-flex align-items-center justify-content-between">
        <button className={`zoom-out mx-2 ${zoomOutClass}`} onClick={zoomOut}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM13.5 10.5h-6" />
          </svg>
        </button>
        <span>{(scale * 100).toFixed()}%</span>
        <button className={`zoom-in mx-2 ${zoomInClass}`} onClick={zoomIn}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
            <path strokeLinecap="round" strokeLinejoin="round" d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607zM10.5 7.5v6m3-3h-6" />
          </svg>
        </button>
        {/* <button className="fullscreen-toggle mx-2 clickable" onClick={toggleFullscreen}>
          <span>{isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}</span>
        </button> */}
        <button className="fullscreen-toggle iframe-toggle mx-2 clickable" onClick={toggleIframe}>
          {/* <span>Popup</span> */}
          <span>{isFullscreen ? 'Exit Fullscreen' : 'Fullscreen'}</span>
        </button>
      </div>
      <style jsx>{`
        .control-panel {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 1rem;
          padding: 1rem;
          background-color: #f8f9fa;
          border-radius: 5px;
          box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        }

        .control-panel button {
          background: none;
          border: none;
          cursor: pointer;
          padding: 0;
          margin: 0;
        }

        .control-panel button.disabled {
          cursor: not-allowed;
          opacity: 0.5;
        }

        .control-panel button.clickable {
          cursor: pointer;
          opacity: 1;
        }

        .control-panel .zoom-out,
        .control-panel .zoom-in {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .control-panel svg {
          width: 24px;
          height: 24px;
        }

        .control-panel span {
          margin: 0 1rem;
          font-size: 1rem;
        }

        @media (max-width: 768px) {
          .page-control {
            order: -1;
            margin-bottom: 1rem;
          }

          .zoom-control {
            order: 1;
          }
        }
      `}</style>
    </>
  );
};

export default ControlPanel;
